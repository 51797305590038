<template>
  <v-select
    :label="label"
    :item-text="ItemText"
    :items="items"
    :value="value"
    @input="$emit('input', $event)"
    @change="$emit('change')"
    class="pt-0 mt-0 pb-0 mb-0"
    hide-details
  />
</template>
<script>
export default {
  props: ["value", "label", "items", "disabled", "ItemText"],
};
</script>
