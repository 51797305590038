import { render, staticRenderFns } from "./PurchaseDeadlineForm.vue?vue&type=template&id=262f9633&scoped=true&"
import script from "./PurchaseDeadlineForm.js?vue&type=script&lang=js&"
export * from "./PurchaseDeadlineForm.js?vue&type=script&lang=js&"
import style0 from "@/components/pages/common/scss/purchase.scss?vue&type=style&index=0&id=262f9633&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "262f9633",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VMain})
