// atoms
import NoPaddingLabelSelect from "@/components/atoms/VSelect/NoPaddingLabelSelect";
import MultipleSelect from "@/components/atoms/VSelect/MultipleSelect";
import PaddingGrayButton from "@/components/atoms/VButton/PaddingGrayButton";
// organisms
import LoadingSpinner from "@/components/organisms/LoadingSpinner/LoadingSpinner";
import SnackBar from "@/components/organisms/SnackBar/SnackBar";
// calculate
import header from "@/assets/table/purchase/purchaseDeadlineHeaders";
import PurchaseDeadlineMainTable from "@/components/organisms/FilterList/purchDeadReleaseList.vue";
import numFilterDialog from "@/components/organisms/VDialog/numFilterDialog";
import { mapGetters } from "vuex";
export default {
  components: {
    NoPaddingLabelSelect,
    PaddingGrayButton,
    LoadingSpinner,
    MultipleSelect,
    PurchaseDeadlineMainTable,
    SnackBar,
    numFilterDialog,
  },
  data() {
    return {
      header,
      totalArea: [
        "checkBox",
        "year",
        "month",
        "kind",
        "season",
        "order",
        "buyer",
        "item",
        "placeForward",
        "color",
        "salesMonth",
      ],
    };
  },
  computed: {
    ...mapGetters({
      releaseList: "getPurchSalesCalculList",
      calculateTotal: "getCalculateTotal",
    }),
    selected: {
      get() {
        return this.$store.state.selected;
      },
      set(data) {
        this.$store.state.selected = data;
      },
    },
    purchaseUnitPrice() {
      return this.calculateTotal.totalAmount &&
        this.calculateTotal.purchaseQuantity
        ? parseFloat(
            (
              this.calculateTotal.totalAmount /
              this.calculateTotal.purchaseQuantity
            ).toFixed(2),
          )
        : 0;
    },
  },

  methods: {
    confirmDialogOpen(kind) {
      this.setCheckSelected();
      if (kind === "cancel" && !this.checkSelected()) {
        this.$store.commit("setSnackBar", `마감 취소 할 건을 선택해주세요.`);
        return;
      }
      if (
        confirm(
          `${this.$store.state.selected.length} 건을 ${
            this.$store.state.auth_grade >= 2 ? "마감 취소" : "마감 취소 요청"
          } 하시겠습니까?`,
        )
      ) {
        this.$store.dispatch("TASK_SELECT_PURCHASECAC_SAVE", kind);
      }
    },
    checkSelected() {
      return this.$store.state.selected.length ? true : false;
    },
    setCheckSelected() {
      const selected = [];
      this.releaseList.forEach((x) => {
        if (x.checkBox) selected.push(x);
      });
      this.$store.commit("setSelected", selected);
    },
  },
  async created() {
    this.$store.commit("setSelected");

    // this.$store.dispatch("SET_API_LOADING", false);
  },
  sockets: {
    // async purchase(data) {
    //   if (this.$store.state.auth_company === data.company)
    //     await this.$store.dispatch("GET_RELEASELIST", {
    //       kind: "purchasedeadline",
    //     });
    // },
  },
};
